import { Section, Template } from '@/core/helpers/template/TemplateInterface'
import { uuid } from 'vue-uuid'
import { TemplateLocator } from '@/store/modules/TemplateModule'

export const buildWidgetLocator = (activityTemplate, widgetUuid) => {
  const widgetLocator = {
    activeSectionIndex: -1,
    activeWidgetIndex: -1
  } as TemplateLocator

  const templateJson = activityTemplate.value.templateJson

  for (let s = 0; s < templateJson.sections.length; s++) {
    widgetLocator.activeSectionIndex = s
    const section = templateJson.sections[s]
    for (let w = 0; w < section.widgets.length; w++) {
      if (section.widgets[w].widgetUuid === widgetUuid) {
        widgetLocator.activeWidgetIndex = w
        return widgetLocator
      }
    }
  }

  return widgetLocator
}

export const generateNewSection = (): Section => {
  return {
    sectionUuid: uuid.v4(),
    title: 'New section',
    color: null,
    backgroundImage: null,
    widgets: [],
    widgetsMobilePosition: [],
    settings: {
      enabled: false,
      locked: false,
      lockedPassword: '',
      lockedPasswordHint: ''
    } as any
  }
}

export const generateNewTemplate = (): Template => {
  return {
    templateUuid: uuid.v4(),
    title: 'Untitled',
    description: 'No description',
    sections: [
      {
        sectionUuid: uuid.v4(),
        title: 'Intro',
        color: null,
        backgroundImage: null,
        widgets: [],
        widgetsMobilePosition: [],
        settings: {
          enabled: true,
          locked: false,
          lockedPassword: '',
          lockedPasswordHint: ''
        } as any
      },
      {
        sectionUuid: uuid.v4(),
        title: 'New section',
        color: null,
        backgroundImage: null,
        widgets: [],
        widgetsMobilePosition: [],
        settings: {
          enabled: false,
          locked: false,
          lockedPassword: '',
          lockedPasswordHint: ''
        } as any
      }
    ]
  }
}
