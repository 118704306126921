
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { setCurrentPageTitle, setCurrentPageTitleButton } from '@/core/helpers/breadcrumb'
import ActivityCard2 from '@/views/mc-admin-library-manager/ActivityCard2.vue'
import { generateNewTemplate } from '@/core/helpers/template/template'
import { ActivityTemplateFilter, NewTemplatePayload } from '@/store/modules/TemplateModule'
import { Plus } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { MenuComponent } from '@/assets/ts/components'
import {
  buildTemplateImageUrl,
  buildTemplateImageUri,
  getEtagForTemplate,
  formatSecondsToMinutes,
  formatActivitiesTableDate
} from '@/core/mc-admin-helpers/activity-template'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'activity-manager',
  components: {
    ActivityCard2
  },
  setup () {
    const store = useStore()
    const createActivityLoading = ref<Boolean>(false)
    const router = useRouter()
    const lastPath = router.options.history.state.back
    const prevRoutePath = computed(() => lastPath || '/')
    const checkboxVisibilityGroup = ref(['company', 'library'])
    const checkedTags = ref([])
    const filterByKeyword = ref(null) as any
    const currentCompany = computed(() => store.getters.getCompany)
    const selectedActivityTemplate = ref({}) as any

    watch(filterByKeyword, (newValue, oldValue) => {
      if (filterByKeyword === '') {
        store.commit(Mutations.SET_SEARCH_BY_KEYWORD_FILTER, null)
        return
      }
      store.commit(Mutations.SET_SEARCH_BY_KEYWORD_FILTER, newValue)
    })

    const changeTags = (changedValue) => {
      store.commit(Mutations.SET_FILTER_USED_TAGS, changedValue)
    }
    const onCreateNewActivity = () => {
      createActivityLoading.value = true
      const newTemplateJson = generateNewTemplate()
      const newTemplatePayload = {
        title: 'Untitled',
        description: 'No description',
        objective: 'Objective',
        templateJson: JSON.stringify(newTemplateJson),
        cluesJson: '[]',
        solutionJson: '[]'
      } as NewTemplatePayload

      store.dispatch(Actions.API_CREATE_ACTIVITY_TEMPLATE, newTemplatePayload).then((response) => {
        createActivityLoading.value = false
        window.location.href = '/builder/' + response.templateUuid
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        }).then(okay => {
          if (okay) {
            createActivityLoading.value = false
          }
        })
      })
    }
    const activityTemplates = ref(computed(() => store.getters.getActivityTemplatesFiltered)) as any
    const currentAccount = ref(computed(() => store.getters.currentAccount))
    const allAvailableTags = ref(computed(() => store.getters.getTagsAvailable))
    const handleCheckboxVisibilityChange = (updatedValue) => {
      if (updatedValue.includes('library')) {
        store.commit(Mutations.SET_PUBLIC_VISIBILITY_ON_ACTIVITY_TEMPLATES, -1)
      } else {
        store.commit(Mutations.SET_PUBLIC_VISIBILITY_ON_ACTIVITY_TEMPLATES, null)
      }
      if (updatedValue.includes('company')) {
        store.commit(Mutations.SET_COMPANY_VISIBILITY_ON_ACTIVITY_TEMPLATES, currentAccount.value.company.id)
      } else {
        store.commit(Mutations.SET_COMPANY_VISIBILITY_ON_ACTIVITY_TEMPLATES, null)
      }
    }
    onMounted(() => {
      setCurrentPageTitle('Build from scratch')
      setCurrentPageTitleButton({})
      watch(() => currentAccount.value, (newValue, oldValue) => {
        const payload: ActivityTemplateFilter = { filter: { limit: 1000, offset: 0, isPublished: true } }

        store.dispatch(MutationsActions.API_GET_MUTATE_AVAILABLE_TAGS).then(() => {
          store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload).then(() => {
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT).then(() => {
              if (checkboxVisibilityGroup.value.includes('library')) {
                store.commit(Mutations.SET_PUBLIC_VISIBILITY_ON_ACTIVITY_TEMPLATES, -1)
              }
              if (checkboxVisibilityGroup.value.includes('library')) {
                store.commit(Mutations.SET_COMPANY_VISIBILITY_ON_ACTIVITY_TEMPLATES, currentAccount.value.company.id)
              }
              if (filterByKeyword === '') {
                store.commit(Mutations.SET_SEARCH_BY_KEYWORD_FILTER, null)
              }
              store.commit(Mutations.SET_FILTER_USED_TAGS, [])

              MenuComponent.reinitialization()
            })
          })
        })
      })

      if (currentAccount.value) {
        const payload: ActivityTemplateFilter = { filter: { limit: 1000, offset: 0, isPublished: true } }

        store.dispatch(MutationsActions.API_GET_MUTATE_AVAILABLE_TAGS).then(() => {
          store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload).then(() => {
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT).then(() => {
              if (checkboxVisibilityGroup.value.includes('library')) {
                store.commit(Mutations.SET_PUBLIC_VISIBILITY_ON_ACTIVITY_TEMPLATES, -1)
              }
              if (checkboxVisibilityGroup.value.includes('library')) {
                store.commit(Mutations.SET_COMPANY_VISIBILITY_ON_ACTIVITY_TEMPLATES, currentAccount.value.company.id)
              }
              if (filterByKeyword === '') {
                store.commit(Mutations.SET_SEARCH_BY_KEYWORD_FILTER, null)
              }
              store.commit(Mutations.SET_FILTER_USED_TAGS, [])

              MenuComponent.reinitialization()
            })
          })
        })
      }
    })

    const onBuildFromActivity = (template) => {
      createActivityLoading.value = true

      const payload = {} as any
      payload.templateUuid = template.activityTemplateUuid
      payload.title = template.title

      store.dispatch(Actions.API_DUPLICATE_TEMPLATE, payload).then((response) => {
        createActivityLoading.value = false
        window.location.href = '/builder/' + response.payload.activityTemplateUuid
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        }).then(okay => {
          if (okay) {
            createActivityLoading.value = false
          }
        })
      })
    }

    return {
      onCreateNewActivity,
      onBuildFromActivity,
      activityTemplates,
      createActivityLoading,
      buildTemplateImageUrl,
      buildTemplateImageUri,
      currentAccount,
      currentCompany,
      checkboxVisibilityGroup,
      changeTags,
      allAvailableTags,
      filterByKeyword,
      checkedTags,
      handleCheckboxVisibilityChange,
      getEtagForTemplate,
      formatSecondsToMinutes,
      formatActivitiesTableDate,
      prevRoutePath,
      Plus,
      selectedActivityTemplate
    }
  }
})
